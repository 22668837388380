var exports = {};
exports = {
  data: {
    id: "1",
    type: "webhook",
    attributes: {
      type: "ticket.attending",
      url: "http://foo.com/bar",
      provider: "zapier",
      status: "active",
      createdAt: "2020-02-29T15:12:12.435Z",
      updatedAt: "2020-02-29T15:12:12.435Z"
    }
  }
};
export default exports;