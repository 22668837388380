var exports = {};
exports = {
  type: "ticket.attending",
  url: "http://foo.com/bar",
  provider: "zapier",
  status: "active",
  createdAt: "2020-02-29T15:12:12.435Z",
  updatedAt: "2020-02-29T15:12:12.435Z",
  id: "1"
};
export default exports;