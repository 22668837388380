var exports = {};
exports = {
  data: {
    id: "288298",
    type: "payment",
    attributes: {
      name: "Foo Bar",
      email: "foo@bar.com",
      company: "The Company",
      amount: 13,
      vat: 3,
      vatPercentage: 30,
      token: "2447b4acef764836169b53c4",
      currency: "SEK",
      status: "paid",
      paidAt: "2020-03-09T20:05:10.000Z",
      commission: "5.65",
      commissionVat: "1.13",
      customer: {
        other: "Extra information"
      }
    }
  },
  meta: {
    webhookType: "payment.paid"
  }
};
export default exports;