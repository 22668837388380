var exports = {};
exports = {
  data: {
    id: 1,
    type: "workspace",
    attributes: {
      name: "My workspace"
    }
  }
};
export default exports;