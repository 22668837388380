import * as _dotenv2 from "dotenv";

var _dotenv = "default" in _dotenv2 ? _dotenv2.default : _dotenv2;

import _adapter from "./adapter";
import _resources from "./resources";
import _models from "./models";
var exports = {};
const dotenv = _dotenv;
dotenv.config();
const adapter = _adapter;
const resources = _resources;
const models = _models;

class Confetti {
  constructor(settings = {}) {
    Object.assign(this, resources({
      adapter: adapter({
        apiKey: settings.apiKey,
        fetch: settings.fetch,
        apiHost: settings.apiHost,
        apiProtocol: settings.apiProtocol
      }),
      models
    }));
  }

}

Confetti.models = models;
Object.assign(Confetti, resources({
  adapter: adapter(),
  models
}));
exports = Confetti;
export default exports;