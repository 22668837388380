var exports = {};
exports = {
  data: {
    id: 1,
    type: "event",
    attributes: {
      name: "My event"
    }
  }
};
export default exports;