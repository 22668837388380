import _samples from "./samples";
import _block from "./block";
import _category from "./category";
import _contact from "./contact";
import _event from "./event";
import _image from "./image";
import _page from "./page";
import _payment from "./payment";
import _ticket from "./ticket";
import _webhook from "./webhook";
import _workspace from "./workspace";
var exports = {};

const samples = _samples();

exports = {
  block: _block({
    samples
  }),
  category: _category({
    samples
  }),
  contact: _contact({
    samples
  }),
  event: _event({
    samples
  }),
  image: _image({
    samples
  }),
  page: _page({
    samples
  }),
  payment: _payment({
    samples
  }),
  ticket: _ticket({
    samples
  }),
  webhook: _webhook({
    samples
  }),
  workspace: _workspace({
    samples
  })
};
export default exports;