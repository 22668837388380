var exports = {};
exports = {
  id: "588032",
  firstName: "Jonny",
  lastName: "Stromberg",
  email: "jonny@foo.bar",
  phone: "+46701122333",
  token: "gsd00876ec00cdef2233b4ace769c54sdf46",
  status: "active",
  comment: "Hey comment",
  lastSeen: "2022-01-05T15:33:13.171Z",
  createdAt: "2022-01-05T15:33:13.171Z",
  updatedAt: "2022-01-05T15:33:13.171Z",
  organisationId: 57,
  type: "contact",
  meta: {
    webhookType: "contact.created"
  }
};
export default exports;