import * as _yayson2 from "yayson";

var _yayson = "default" in _yayson2 ? _yayson2.default : _yayson2;

import _webhook from "./webhook";
import _event from "./event";
import _workspace from "./workspace";
var exports = {};

const {
  Presenter
} = _yayson();

const presenters = {};

const WebhookPresenter = _webhook({
  presenters,
  Presenter
});

const EventPresenter = _event({
  presenters,
  Presenter
});

const WorkspacePresenter = _workspace({
  presenters,
  Presenter
});

presenters.WebhookPresenter = WebhookPresenter;
presenters.EventPresenter = EventPresenter;
presenters.WorkspacePresenter = WorkspacePresenter;
exports = {
  webhooks: WebhookPresenter,
  events: EventPresenter,
  workspaces: WorkspacePresenter
};
export default exports;