import * as _lodash2 from "lodash";

var _lodash = "default" in _lodash2 ? _lodash2.default : _lodash2;

import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
const _ = _lodash;
const util = _util;

const customError = function (name) {
  var error;

  error = function (type, options) {
    if (options == null) {
      options = {};
    }

    Error.call(this || _global);
    (this || _global).name = name;
    (this || _global).message = type;
    (this || _global).errorType = type;
    Error.captureStackTrace(this || _global, (this || _global).constructor);
    return Object.assign(this || _global, options);
  };

  util.inherits(error, Error);
  error.prototype.name = name;
  return error;
};

exports = {
  UnauthorizedError: customError("UnauthorizedError"),
  ParameterError: customError("ParameterError"),
  NotFoundError: customError("NotFoundError")
};
export default exports;