import * as _url2 from "url";

var _url = "default" in _url2 ? _url2.default : _url2;

import * as _qs2 from "qs";

var _qs = "default" in _qs2 ? _qs2.default : _qs2;

import _errors from "./errors";
import * as _yayson2 from "yayson";

var _yayson = "default" in _yayson2 ? _yayson2.default : _yayson2;

import _presenters from "./presenters";
import * as _nodeFetch2 from "node-fetch";

var _nodeFetch = "default" in _nodeFetch2 ? _nodeFetch2.default : _nodeFetch2;

import _process from "process";
var exports = {};
var process = _process;
const url = _url;
const qs = _qs;
const {
  ParameterError,
  NotFoundError
} = _errors;

const {
  Store,
  Presenter
} = _yayson();

const store = new Store();
const presenters = _presenters;

exports = function ({
  apiKey,
  fetch,
  apiHost,
  apiProtocol
} = {}) {
  const httpRequest = async function (method, options) {
    let {
      path,
      json,
      filter,
      include,
      sort,
      page,
      raw,
      type
    } = options;
    const API_HOST = options.apiHost || apiHost || process.env.API_HOST || "api.confetti.events";
    const API_PROTOCOL = options.apiProtocol || apiProtocol || process.env.API_PROTOCOL || "https";
    let API_KEY = options.apiKey || apiKey;
    let fetchLib = options.fetch || fetch;

    if (!fetchLib) {
      fetchLib = _nodeFetch;
    }

    if (!API_KEY) {
      throw new Error("missing_api_key");
    }

    if (Array.isArray(include)) {
      include = encodeURI(include.join(","));
    }

    const httpOptions = {
      method,
      timeout: method === "get" ? 5000 : 15000,
      headers: {
        Authorization: `apikey ${API_KEY}`,
        "Content-Type": "application/json",
        "Accept-Encoding": "gzip"
      }
    };
    const fetchUrl = url.format({
      host: API_HOST,
      protocol: API_PROTOCOL,
      pathname: path,
      search: qs.stringify({
        filter,
        sort,
        page,
        include
      })
    });

    if (json) {
      httpOptions.body = JSON.stringify(presenters[type].render(json));
    }

    const res = await fetchLib(fetchUrl, httpOptions);

    if (res.status >= 200 && res.status < 299) {
      const contentType = res.headers.get("content-type") || "";

      if (contentType.includes("application/json")) {
        const body = await res.json();

        if (raw) {
          return body;
        } else {
          return store.sync(body);
        }
      } else {
        return await res.text();
      }
    } else {
      let errorBody;

      if (res.status >= 400 && res.status < 499) {
        const contentType = res.headers.get("content-type") || "";

        if (contentType.includes("application/json")) {
          errorBody = await res.json();
        } else {
          errorBody = await res.text();
        }
      }

      if (res.status == 400) {
        throw new ParameterError("validation", errorBody);
      } else if (res.status == 404) {
        throw new NotFoundError(errorBody.message || "object", errorBody);
      } else {
        throw new Error();
      }
    }
  };

  const adapter = {
    async put(options) {
      return await httpRequest("put", options);
    },

    async post(options) {
      return await httpRequest("post", options);
    },

    async get(options) {
      return await httpRequest("get", options);
    },

    async delete(options) {
      return await httpRequest("delete", options);
    }

  };
  return adapter;
};

export default exports;