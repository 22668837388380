import _raw from "./category/raw";
import _formatted from "./category/formatted";
import _raw2 from "./contact/raw";
import _formatted2 from "./contact/formatted";
import _raw3 from "./event/raw";
import _formatted3 from "./event/formatted";
import _raw4 from "./image/raw";
import _formatted4 from "./image/formatted";
import _raw5 from "./payment/raw";
import _formatted5 from "./payment/formatted";
import _raw6 from "./ticket/raw";
import _formatted6 from "./ticket/formatted";
import _raw7 from "./webhook/raw";
import _formatted7 from "./webhook/formatted";
import _raw8 from "./workspace/raw";
import _formatted8 from "./workspace/formatted";
var exports = {};
const samples = {
  category: {
    raw: _raw,
    formatted: _formatted
  },
  contact: {
    raw: _raw2,
    formatted: _formatted2
  },
  event: {
    raw: _raw3,
    formatted: _formatted3
  },
  image: {
    raw: _raw4,
    formatted: _formatted4
  },
  payment: {
    raw: _raw5,
    formatted: _formatted5
  },
  ticket: {
    raw: _raw6,
    formatted: _formatted6
  },
  webhook: {
    raw: _raw7,
    formatted: _formatted7
  },
  workspace: {
    raw: _raw8,
    formatted: _formatted8
  }
};

exports = function () {
  const getSample = model => {
    const raw = samples[model].raw;
    const formatted = samples[model].formatted;
    const rawMultiple = {
      data: [raw.data, raw.data]
    };
    const formattedMultiple = [JSON.parse(JSON.stringify(formatted)), JSON.parse(JSON.stringify(formatted))];
    delete formattedMultiple[0].meta;
    delete formattedMultiple[1].meta;
    return {
      single: {
        formatted: formatted,
        raw: raw
      },
      multiple: {
        formatted: formattedMultiple,
        raw: rawMultiple
      }
    };
  };

  const models = ["category", "contact", "event", "image", "payment", "ticket", "webhook", "workspace"];
  return models.reduce((result, key) => {
    result[key] = getSample(key);
    return result;
  }, {});
};

export default exports;