var exports = {};
exports = {
  data: {
    id: "16500",
    type: "event",
    attributes: {
      name: "My first event",
      startDate: "2020-09-19T16:00:00.000Z",
      endDate: "2020-09-19T19:00:00.000Z",
      timeZone: "Europe/Berlin",
      slug: "green-summer-18717b",
      status: "open",
      featureLevel: "business",
      signupType: "rsvp",
      website: "http://jonny-action.confetti.test/my-first-event",
      email: "jonny.stromberg@gmail.com",
      rsvpLimit: 100,
      rsvpLeft: 85,
      waitlisted: 0,
      hasPassed: false,
      createdAt: "2018-10-11T13:06:16.432Z",
      updatedAt: "2020-03-09T20:19:40.956Z",
      summary: "Write a three sentence pitch for your event here.",
      timeFormat: "24",
      locale: "en",
      primaryColor: "#00DB7D",
      contrastColor: "#ffaa99",
      waitlist: true,
      location: {
        url: "http://www.google.com/maps?q=undefined"
      }
    },
    relationships: {
      images: {
        data: null
      }
    }
  },
  meta: {
    webhookType: "event.updated"
  }
};
export default exports;