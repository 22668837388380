var exports = {};
exports = {
  data: {
    id: "3344691",
    type: "ticket",
    attributes: {
      persons: 1,
      hashid: "g265xg",
      description: "My cool batch",
      price: 10,
      currency: "SEK",
      name: "Foo Bar",
      email: "foo@bar.com",
      phone: "+46701111111",
      token: "d92614282978451b7942fffda421df47740c",
      status: "attending",
      emailStatus: "subscribed",
      checkinAt: null,
      waitlistAt: null,
      startDate: "2021-06-18T16:00:00.000Z",
      endDate: null,
      values: {
        "field-one-field": "Veg",
        "field-what-do-you-think": ["Yes"]
      },
      comment: null,
      guests: 0,
      termsAcceptedAt: "2020-03-09T19:53:30.304Z",
      deletionRequestedAt: null,
      createdAt: "2020-03-09T19:51:32.274Z",
      updatedAt: "2020-03-09T19:53:30.354Z",
      ticketBatchId: 16090,
      paymentId: 288297,
      eventId: 16969,
      contactId: 112395
    }
  },
  meta: {
    webhookType: "ticket.attending"
  }
};
export default exports;